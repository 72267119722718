import React, {useEffect , useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import About from './components/about';
import Services from './components/services';
import Projects from './components/projects';
import ServicesPage from './components/services/services-page';
import ProjectPage from './components/projects/project-page';
import Contact from './components/contact';
import Loader from './components/loader';
import axios from 'axios';
// slider
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// url
const url = "https://backend.alridainvestment.com";

const App = () => {

  const [data , setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
        
    const lang = localStorage.getItem('lang') || 'en';
    axios.get(`${url}/homepage/homepage/${lang}/`)
    .then(res => setData(res.data))
    .finally(() => {
      setIsLoading(false);
    })

  },[])

  return (
    <div className="App">
      <BrowserRouter>
      {isLoading ? (
          <Loader />
        ) : (
      <Routes>
        <Route exact path="*" element={<Home data={data} />} />
        <Route exact path="/" element={<Home data={data} />} />
        <Route path="/about" element={<About data={data} />} />
        <Route path="/services" element={<Services data={data} />} />
        <Route path="/projects" element={<Projects data={data} />} />
        <Route path="/projectpage/:id" element={<ProjectPage />} />
        <Route path="/servicespage/:id" element={<ServicesPage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      )}
      </BrowserRouter>
    </div>
  );
}

export default App;
