import React , { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../nav";
import Sliders03 from "../slider/Sliders03";
import AboutComp from "../about/about";
import ServicesComp from "../services/services";
import Testimonials from "../promo&map/promoMap";
import Footer from "../footer";
import i18next from "i18next";
// import OwlCarousel from 'react-owl-carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";

// url
const url = "https://backend.alridainvestment.com";
// owlcarousel
const responsiveOptions = {
    0: {
        items: 1
    , }
    , 600: {
        items: 2
    }
    , 1000: {
        items: 2
    }
};

const Home = ({data}) => {    

    const [isHeaderToggled, setIsHeaderToggled] = useState(false);

    useEffect(() => {
        
        // if (data.slider) {
        //     data.slider.forEach((item) => {
        //       const image = new Image();
        //       image.src = `${url}${item.image}`;
        //     });
        //   }

        // bg img
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
        // Navbar scrolling background
        // window.addEventListener("scroll", function () {
        //     var bodyScroll = window.scrollY,
        //         navbar = document.querySelector(".navbar");
        //     if (bodyScroll > 100) {
        //     navbar.classList.add("nav-scroll");
        //     } else {
        //     navbar.classList.remove("nav-scroll");
        //     }
        // });

    },[])

    const toggleHeader = () => {
        setIsHeaderToggled(!isHeaderToggled);
    };

    return (
    <Fragment>
    <>
    {/* Nav */}
    <a href="#" className="js-bauen-nav-toggle bauen-nav-toggle" onClick={toggleHeader}><i></i></a>
    <Nav isHeaderToggled = {isHeaderToggled} />
    {/* Content */}
    <div id="bauen-main">
    {/* Slider */}
    <Sliders03 data={data}/>
    <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
            <div className="content-lines-inner">
                <div className="content-lines"></div>
            </div>
        </section>
        {/* <!-- About --> */}
        <AboutComp data={data} />
        {/* <!-- Services --> */}
        <section className="services section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title">{i18next.t('Our')} <span>{i18next.t('Services')}</span></h2>
                    </div>
                </div>
                <ServicesComp data={data} />
            </div>
        </section>
        {/* <!-- Projects --> */}
        <section className="projects section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title">{i18next.t('Our')} <span>{i18next.t('Projects')}</span></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <OwlCarousel className="owl-carousel owl-theme"
                                loop= {false}
                                margin= {30}
                                mouseDrag= {true}
                                autoplay= {false}
                                dots= {true}
                                autoplayHoverPause={false}
                                responsiveClass= {true}
                                responsive = {responsiveOptions}
                                preventScrollOnTouch={true} // add this prop
                        >
                        {data && data.projects ?
                        data.projects.map((item , index) => {
                            return(
                                <div className="item" key={index}>
                                <Link to={`/projectpage/${index}`} state={data.projects}>
                                <div className="position-re o-hidden"> <img src={`${url}${item.project_live_images[0].image}`} alt="" /> </div>
                                <div className="con">
                                    <h6>{item.id}</h6>
                                    <h5>{item.name}</h5>
                                    <div className="line"></div><i className="ti-arrow-right"></i>
                                </div>
                                </Link>
                                </div>
                            )
                        })
                        : null
                        }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Contact --> */}
        {/* <section id="contact" className="section-padding" data-scroll-index="5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
                        <h2 className="section-title">Contact <span>Us</span></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <p><b>Phone :</b> +964</p>
                    </div>
                    <div className="col-md-4 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <p><b>Email :</b> info@alridainvestment.com</p>
                    </div>
                    <div className="col-md-4 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <p><b>Address :</b> District 915 - Street 33, House 3, Al-Jadriya - Baghdad - Iraq.</p>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- Promo video - Map - Clients --> */}
        <Testimonials data={data.companies}/>
        {/* <!-- Footer --> */}
        <Footer />
    </div>
    </div>
    </>
    </Fragment>
    );
}

export default Home;
