import React , { Fragment, useEffect } from "react";
import Nav from "../nav";
import AboutComp from "./about";
import Testimonials from "../promo&map/promoMap";
import Footer from "../footer";

const About = ({data}) => {    

    useEffect(() => {
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
    })

  return (
    <Fragment>
    {/* Nav */}
    <Nav />
    {/* Content */}
    <div id="bauen-main" className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
            <div className="content-lines-inner">
                <div className="content-lines"></div>
            </div>
        </section>
        {/* <!-- Header Banner --> */}
        <section className="banner-header banner-img valign bg-img bg-fixed" data-overlay-light="3" data-background="img/banner.jpg">
            {/* <!-- Left Panel --> */}
            <div className="left-panel"></div>
        </section>
        {/* <!-- About --> */}
        <AboutComp data={data} />
        {/* <!-- Promo video - Map - Clients --> */}
        {/* <Testimonials /> */}
        {/* <!-- Footer --> */}
        <Footer />
    </div>
    </Fragment>
  );
}

export default About;
