import i18next from "i18next";
const Footer = () => {
  return (
    // <!-- Footer -->
    <footer className="main-footer dark">
        <div className="sub-footer">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-4">
                        <div className="item fotcont">
                            <div className="fothead">
                                <h6>{i18next.t('Email')}</h6>
                            </div>
                            <p>info@alridainvestment.com</p>
                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                            <div className="fothead">
                                <h6>{i18next.t('ALRIDA')}</h6>
                            </div>
                        <p>2023 © {i18next.t('All Rights Reserved.')}</p>
                        {/* <div className="social-icon"> <a href="https://www.facebook.com/" target="_blank_"><i className="ti-facebook"></i></a> <a href="https://www.facebook.com/" target="_blank_"><i className="ti-linkedin"></i></a> <a href="https://www.facebook.com/" target="_blank_"><i className="ti-instagram"></i></a> <a href="https://www.facebook.com/" target="_blank_"><i className="ti-youtube"></i></a> </div> */}
                    </div>
                    <div className="col-md-4">
                        <div className="item fotcont">
                            <div className="fothead">
                                <h6>{i18next.t('Our Address')}</h6>
                            </div>
                            <p>{i18next.t('Baghdad - Iraq')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;

