import { Link, NavLink } from "react-router-dom";
import i18next from "i18next";
import { useLocation } from 'react-router-dom';

const Nav = (props) => {
    const changeLang = (e) => {
        localStorage.setItem('lang',e.target.value);
        window.location.href = '/';
    }

    const lang = localStorage.getItem('lang') || 'en';
    const { pathname } = useLocation();
    return (
    //Navbar
    <aside id="bauen-aside" className={props.isHeaderToggled ? 'headerToggle' : ''}>
    {/* <!-- Logo --> */}
    <div className="bauen-logo">
        <Link to="/"> <img src="img/logo.png" className="logo-img" alt="" />
            <h2>ALRIDA<span>ALRIDA investment</span></h2>
        </Link>
    </div>
    {/* <!-- Menu --> */}
    <nav className="bauen-main-menu">
        <ul>
                <li className="nav-item"><NavLink to="/">{i18next.t('Home')} </NavLink></li>
                <li className="nav-item"><NavLink to="/about">{i18next.t('About')} </NavLink></li>
                <li className="nav-item"> <NavLink to="/services">{i18next.t('Services')} </NavLink></li>
                <li className="nav-item"> <NavLink to="/projects">{i18next.t('Projects')} </NavLink></li>
                <li className="nav-item"><NavLink to="/contact">{i18next.t('Contact')} </NavLink></li>
                <li className="nav-item">
                    <select className="nav-item selectI18n" onChange={changeLang} value={lang}>
                        <option value="en">En</option>
                        <option value="ar">عربي</option>
                    </select>
                </li>
        </ul>
    </nav>
    {/* <!-- Sidebar Footer --> */}
    <div className="bauen-footer">
        <ul>
        2023 © ALRIDA
        </ul>
    </div>
    </aside>
    );
}

export default Nav;
