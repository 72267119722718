import React , { Fragment, useEffect } from "react";
import Nav from "../nav";
import Testimonials from "../promo&map/promoMap";
import Footer from "../footer";
import i18next from "i18next";

const Contact = () => {    

    useEffect(() => {
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
    })

  return (
    <Fragment>
    {/* Nav */}
    <Nav />
    {/* Content */}
    <div id="bauen-main" className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
            <div className="content-lines-inner">
                <div className="content-lines"></div>
            </div>
        </section>
        {/* <!-- Header Banner --> */}
        <section className="banner-header banner-img valign bg-img bg-fixed" data-overlay-light="3" data-background="img/banner.jpg">
            {/* <!-- Left Panel --> */}
            <div className="left-panel"></div>
        </section>
        {/* <!-- Contact --> */}
        <section className="section-padding2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
                        <h2 className="section-title">{i18next.t('Contact Us')}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <p><b>{i18next.t('Phone')} :</b> +964</p>
                    </div>
                    <div className="col-md-4 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <p className="text-center"><b>{i18next.t('Email')} :</b> info@alridainvestment.com</p>
                    </div>
                    <div className="col-md-4 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <p className="text-end"><b>{i18next.t('Address')} :</b> {i18next.t('Baghdad - Iraq')}</p>
                    </div>
                </div>
                {/* <!-- Map Section --> */}
                <div className="row">
                    <div className="col-md-12 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d213393.71769630729!2d44.52071449999455!3d33.31157726091991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15577f67a0a74193%3A0x9deda9d2a3b16f2c!2z2KjYutiv2KfYrw!5e0!3m2!1sar!2siq!4v1699126435373!5m2!1sar!2siq" width="100%" height="500" style={{"border":"0"}} allowfullscreen="" loading="lazy" className="map"></iframe>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Promo video - Map - Clients --> */}
        {/* <Testimonials /> */}
        {/* <!-- Footer --> */}
        <Footer />
    </div>
    </Fragment>
  );
}

export default Contact;
