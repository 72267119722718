import React from "react";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const ServicesComp = (props) => { 
    const location = useLocation();
    const { data } = props;
    if (!data || !data.about) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { services } = data;
    const numberOfItemsToDisplay = location.pathname !== '/services' ? 3 : services.length;
    return (
    <div className="row">
        {services.slice(0, numberOfItemsToDisplay).map((item,index) => {
            return(
                
                    <div className="col-lg-4" key={services.id}>
                    <Link to={`/servicespage/${index}`} state={services}>
                    <div class="blogBox">
                    <div class="blogImage">
                        <img src={`https://backend.alridainvestment.com${item.image}`} alt="blog" />
                    </div>
                    <div class="blogInfo">
                        <div class="blogInfoTitle">
                            <h6>{item.title}</h6>
                            <p>{item.subtitle.slice(0,202)}.</p>
                            <Link to={`/servicespage/${index}`} state={services} style={{color : "#0982ac"}}>{i18next.t('see more')} ...</Link>
                        </div>
                    </div>
                    </div>
                    </Link>
                    </div>
            )
        })}
    </div>
    );
    }

export default ServicesComp;
