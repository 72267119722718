import React , { Fragment, useEffect, useState } from "react";
import Nav from "../nav";
import Footer from "../footer";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import i18next from "i18next";
import '/node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '/node_modules/owl.carousel/dist/assets/owl.theme.default.css';


const ServicesPage = () => {  

    const [data , setData] = useState({});

    let { id } = useParams();
    let location = useLocation(); 
    
    useEffect(() => {
        setData(location.state[id])
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
    },[])


  return (
    <Fragment>
    {/* Nav */}
    <Nav />
    {/* Content */}
    <div id="bauen-main" className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
            <div className="content-lines-inner">
                <div className="content-lines"></div>
            </div>
        </section>
        {/* <!-- Header Banner --> */}
        <section className="banner-header banner-img valign bg-img bg-fixed" data-overlay-light="3" data-background="/img/banner.jpg">
            {/* <!-- Left Panel --> */}
            <div className="left-panel"></div>
        </section>
        {/* <!-- Projects --> */}
        <section className="section-padding2">
        {data &&
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title2">{data.title}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {console.log(data)}
                        <p>{data.subtitle}</p>
                    </div>
                </div>
                {/* <div className="row mt-30">
                    {data.image &&
                            <div className="col-md-6 gallery-item">
                            <div className="gallery-box">
                                <div className="gallery-img"> <img src={`https://backend.alridainvestment.com${data.image}`} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                            </div>
                    </div>
                    }
                </div> */}
                <div className="row">
                {data.service_company &&
                data.service_company.map((item,index) => {
                return(
                    
                        <div className="col-lg-4" key={index}>
                        <Link to={item.link} target="_blank_">
                        <div class="blogBox">
                        <div class="blogImage">
                            <img src={`https://backend.alridainvestment.com${item.img}`} alt="blog" />
                        </div>
                        <div class="blogInfo">
                            <div class="blogInfoTitle">
                                <h6>{item.title}</h6>
                                <p>{item.subtitle.slice(0,202)}.</p>
                                <Link to={item.link} target="_blank_" style={{color : "#0982ac"}}>{i18next.t('Company Link')} ...</Link>
                            </div>
                        </div>
                        </div>
                        </Link>
                        </div>
                )
                })}
                </div>
            </div>
            }
        </section>
        {/* <!-- Footer --> */}
        <Footer />

    </div>
    
    </Fragment>
  );
}

export default ServicesPage;
