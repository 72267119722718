import React , { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Nav from "../nav";
import Testimonials from "../promo&map/promoMap";
import Footer from "../footer";
import { Link } from "react-router-dom";
import i18next from "i18next";

const url = "https://backend.alridainvestment.com";

const Projects = () => {    

    const [data , setData] = useState([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'en';
        axios.get(`${url}/projects/project/${lang}/`)
        .then(res => setData(res.data))
        // bg img
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
    } , [])
    // const dataItems = data.map()

  return (
    <Fragment>
    {/* Nav */}
    <Nav />
    {/* Content */}
    <div id="bauen-main" className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
            <div className="content-lines-inner">
                <div className="content-lines"></div>
            </div>
        </section>
        {/* <!-- Header Banner --> */}
        <section className="banner-header banner-img bg-img bg-fixed pb-0" data-background="img/banner.jpg" data-overlay-light="3">
            {/* <!-- Left Panel --> */}
            <div className="left-panel"></div>
        </section>
        {/* <!-- Projects --> */}
        <section className="projects section-padding2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
                        <h2 className="section-title">{i18next.t('Our')} <span>{i18next.t('Projects')}</span></h2>
                    </div>
                </div>
                <div className="row">
                    {data.map((item , index) => {
                        return(
                            <div className="col-md-6 animate-box" data-animate-effect="fadeInUp" key={index}>
                            <Link to={`/projectpage/${index}`} state={data}>
                            <div className="item">
                                <div className="position-re o-hidden">
                                {item.project_live_images && item.project_live_images.length > 0 ? (
                                    <img src={`${url}${item.project_live_images[0].image}`} alt="" />
                                ) : (
                                    <img src="fallback-image.jpg" alt="خطأ في الصورة" />
                                )}
                                </div>
                                <div className="con">
                                    <h6>{item.id}</h6>
                                    <h5>{item.name}</h5>
                                    <div className="line"></div><i className="ti-arrow-right"></i>
                                </div>
                            </div>
                            </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
        {/* <!-- Promo video - Map - Clients --> */}
        {/* <Testimonials /> */}
        {/* <!-- Footer --> */}
        <Footer />
    </div>
    </Fragment>
  );
}

export default Projects;
