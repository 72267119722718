import { Fragment } from "react"
// import OwlCarousel from 'react-owl-carousel';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import i18next from "i18next";

// owlcarousel
const responsiveClientOptions = {
    0: {
        margin: 10
        , items: 3
    }
    , 600: {
        items: 3
    }
    , 1000: {
        items: 5
    }
}

const Testimonials = ({data}) => {
    return (
        // Promo video - Map - Clients
        <Fragment>
        <section className="testimonials">
            <div className="background bg-img bg-fixed section-padding pb-0" data-background="img/slider/r4.jpg" data-overlay-dark="3">
                <div className="container">
                    <div className="row">
                        {/* <!-- Promo video --> */}
                        {/* <div className="col-md-12">
                            <div className="vid-area">
                                <div className="vid-icon">
                                    <a className="play-button vid" href="#/" target="_blank_">
                                        <svg className="circle-fill">
                                            <circle cx="43" cy="43" r="39" stroke="#fff" strokeWidth=".5"></circle>
                                        </svg>
                                        <svg className="circle-track">
                                            <circle cx="43" cy="43" r="39" stroke="none" strokeWidth="1" fill="none"></circle>
                                        </svg> <span className="polygon">
                                            <i className="ti-control-play"></i>
                                        </span> </a>
                                </div>
                                <div className="cont mt-15 mb-30">
                                    <h5>{i18next.t('View promo video')} </h5>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
        <section className="clients">
                <div className="container">
                    <div className="row align-items-center justify-content-center" style={{"direction" : "ltr"}}>
                        <div className="col-md-6">
                            <OwlCarousel className="owl-carousel owl-theme"
                                    loop= {true}
                                    margin= {30}
                                    mouseDrag= {true}
                                    autoplay= {true}
                                    dots= {false}
                                    responsiveClass= {true}
                                    responsive= {responsiveClientOptions}
                            >
                            {
                                data && 
                                data.map(item => {
                                    return(
                                        <div className="clients-logo">
                                        <a href={item.link} target="_blank_"><img src={`https://backend.alridainvestment.com${item.image_logo}`} alt="" /></a>
                                        </div>
                                    )
                                })
                            }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
        </section>
        </Fragment>
    );
  }
  
  export default Testimonials;
  
  