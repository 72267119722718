import React from 'react';
import SwiperCore , { Navigation , Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard03 from './SliderCard03';
SwiperCore.use([Autoplay]);

const Sliders03 = (props) => {
    const { data } = props;
    if (!data || !data.slider) {
        // Handle the case where data or data.services is undefined
        return <div></div>;
    }
    const { slider } = data;
    return (
        <div className="theme_slider_3 p-0">
            <div className="swiper swiper_theme_slider_3">
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 5000, // Autoplay delay in milliseconds
                        disableOnInteraction: false, // Autoplay continues even when the user interacts with the slider
                    }}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                >
                    {slider.map(item => {
                        return(
                        <SwiperSlide key={item.id}>
                            {/* <SliderCard03 bgImg="images/slider/15.jpg" title="The Future Architecture Is Here" /> */}
                            <SliderCard03 key={item.id} bgImg={`https://backend.alridainvestment.com${item.image}`} title={item.title} />
                        </SwiperSlide>
                        )
                    })}
                </Swiper>

                {/* <!-- Add Buttons --> */}
                <div className="swiper-button-prev details_link l-dir pagination-previous"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                <div className="swiper-button-next details_link r-dir pagination-next"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
            </div>


        </div>
    );
};

export default Sliders03;