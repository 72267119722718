import React , { Fragment, useEffect, useState } from "react";
import Nav from "../nav";
import Footer from "../footer";
import { useLocation, useParams } from "react-router-dom";
import i18next from "i18next";
// import OwlCarousel from 'react-owl-carousel';
import OwlCarousel from 'react-owl-carousel';
import '/node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '/node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import './popup.css'
// owlcarousel
const responsiveClientOptions = {
    0: {
        margin: 10
        , items: 3
    }
    , 600: {
        items: 3
    }
    , 1000: {
        items: 5
    }
}

const ProjectPage = () => {  

    const [data , setData] = useState({});
    // popup
    const [popup , setPopup] = useState(false);

    let { id } = useParams();
    let location = useLocation(); 
    
    useEffect(() => {
        setData(location.state[id])
        const pageSection = document.querySelectorAll(".bg-img, section");
        pageSection.forEach(function(element, index) {
            if (element.getAttribute("data-background")) {
                element.style.backgroundImage = "url(" + element.dataset.background + ")";
            }
        });
        // Navbar scrolling background
        // window.addEventListener("scroll", function () {
        //     var bodyScroll = window.scrollY,
        //         navbar = document.querySelector(".navbar");
        //     if (bodyScroll > 100) {
        //     navbar.classList.add("nav-scroll");
        //     } else {
        //     navbar.classList.remove("nav-scroll");
        //     }
        // });

    },[])

    const popupHandler = () => {
        if (popup === false){
            setPopup(true)
        }
        else {
            setPopup(false)
        }
    }

  return (
    <Fragment>
    {/* Nav */}
    <Nav />
    {/* Content */}
    <div id="bauen-main" className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
            <div className="content-lines-inner">
                <div className="content-lines"></div>
            </div>
        </section>
        {/* <!-- Header Banner --> */}
        <section className="banner-header banner-img valign bg-img bg-fixed" data-overlay-light="3" data-background="/img/banner.jpg">
            {/* <!-- Left Panel --> */}
            <div className="left-panel"></div>
        </section>
        {/* <!-- Projects --> */}
        <section className="section-padding2">
        {data &&
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title2">{data.name}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p>{data.brief_description}</p>
                    </div>
                    {/* <div className="col-md-4">
                        <p><b>{i18next.t('Price')} : </b> {data.price}</p>
                        <p><b>{i18next.t('Area')} : </b> {data.area}</p>
                        <p><b>{i18next.t('Number of buildings')} : </b> {data.total_count_building}</p>
                        <p><b>{i18next.t('Apartments and Houses')} : </b> {data.standerd_floor_levels}</p>
                        <p><b>{i18next.t('Number of floors')} : </b> {data.red_house_department}</p>
                        <p><b>{i18next.t('Apartments')} : </b> {data.department_number}</p>
                        <p><b>{i18next.t('Delivered housing units')} : </b> {data.build_to_customer}</p>
                        <p><b>{i18next.t('population in project')} : </b> {data.total_people_count}</p>
                    </div> */}
                </div>
                <div className="row mt-30" onClick={popupHandler}>
                    {data.project_live_images &&
                    data.project_live_images.map(itemImage => {
                        return(
                            <div className="col-md-6 gallery-item">
                            <div className="gallery-box">
                                <div className="gallery-img"> <img src={`https://backend.alridainvestment.com${itemImage.image}`} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                            </div>

                    </div>
                        )
                    })}
                </div>
            </div>
            }
        </section>
        {/* <!-- Prev-Next Projects --> */}
        {/* <section className="projects-prev-next">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-sm-flex align-items-center justify-content-between">
                            <div className="projects-prev-next-left">
                                <a href="armada-center.html"> <i className="ti-arrow-left"></i> Previous Project</a>
                            </div> <a href="projects.html"><i className="ti-layout-grid3-alt"></i></a>
                            <div className="projects-prev-next-right"> <a href="prime-hotel.html">Next Project <i className="ti-arrow-right"></i></a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* clients */}
        <section className="clients">
                <div className="container">
                    <div className="row align-items-center justify-content-center" style={{"direction" : "ltr"}}>
                        <h2 className="text-center mt-4">{i18next.t('Partners')}</h2>
                        <div className="col-md-6">
                            {data && data.companies &&
                            <OwlCarousel className="owl-carousel owl-theme"
                                    loop= {true}
                                    margin= {30}
                                    mouseDrag= {true}
                                    autoplay= {true}
                                    dots= {false}
                                    responsiveClass= {true}
                                    responsive= {responsiveClientOptions}
                            >
                            {data && data.companies ?
                                data.companies.map(item => {
                                    return(
                                        <div className="clients-logo">
                                        <p>{item.title}</p>
                                        <a href={item.link} target="_blank_"><img src={`https://backend.alridainvestment.com${item.image_logo}`} alt="" /></a>
                                        </div>
                                    )
                                })
                            : null
                            }
                            </OwlCarousel>
                        }
                        </div>
                    </div>
                </div>
        </section>
        {/* <!-- Footer --> */}
        <Footer />

        {/* image popup */}
        {popup === true &&
        <section className="popupImage slider-fade">
        <div className="popupIcon" onClick={popupHandler}><i className="ti-close"></i></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    {data.project_live_images && 
                        <OwlCarousel className="owl-carousel owl-theme"
                        items= {1}
                        loop={false}
                        dots= {true}
                        margin= {0}
                        autoplay= {false}
                        nav= {false}
                        >
                        {data.project_live_images &&
                            data.project_live_images.map(itemImage => {
                                return(
                                    <div className="text-end item bg-img">
                                    <div className="gallery-box">
                                        <div className="gallery-img"> <img src={`https://backend.alridainvestment.com${itemImage.image}`} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                                    </div>
                                    </div>
                                )
                        })}
                        </OwlCarousel>
                    }
                    </div>
                </div>
            </div>
        </section>
        }

    </div>
    
    </Fragment>
  );
}

export default ProjectPage;
