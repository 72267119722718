import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const AboutComp = (props) => { 
    const location = useLocation();
    const { data } = props;
    if (!data || !data.about) {
        // Handle the case where data or data.services is undefined
        return <div>No Data Found</div>;
    }
    const { about } = data;

    return (
            <section className="about section-padding">
                <div className="container">
                    <div className="row">
                        <div className={location.pathname === '/about' ? "col-md-12 mb-30 animate-box" : "col-md-6 mb-30 animate-box"} data-animate-effect="fadeInUp">
                            {location.pathname !== '/about' ?
                            <>
                            <h2 className="section-title"><span>{i18next.t('About ALRIDA')}</span></h2>
                            <p>{about[0].subtitle.slice(0,202)}.</p>
                            <Link to="/about" style={{color : "#0982ac"}}>{i18next.t('see more')} ...</Link>
                            </>
                            :
                            <>
                            <h2 className="section-title"><span>{i18next.t("Chairman's Address")}</span></h2>
                            <p>{about[0].subtitle2}</p>
                            <h2 className="section-title mt-30"><span>{i18next.t('About ALRIDA')}</span></h2>
                            <p>{about[0].subtitle}.</p>
                            </>
                            }
                            {/* <p><b>{i18next.t('Banking, and Accounting Services. In 2015 Al Rida Investment group was formed to act as a Holding company,')}</b> {i18next.t(' following that SPV was created for each type of investment to realize the full potential of given business activity.')}</p> */}
                        </div>
                        {location.pathname !== '/about' &&
                        <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                            <div className="about-img">
                                <div className="img"> <img src="img/slider/r4.jpg" className="img-fluid" alt="" /> </div>
                                <div className="about-img-2 about-buro">{i18next.t('ALRIDA')}</div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
    );
    }

export default AboutComp;
